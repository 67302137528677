import uid from '@/utils/uid'
// import icons from '@/config/icons'
// import randomNumber from '~/utils/randomNumber'

export const config = {
  title: 'Social links',

  root: {
    props: {},
    settings: {
      items: null,
    },
  },
}

export function SocialLinks({ settings, alias = 'socialLinks' } = {}) {
  // const randomIconIndex = randomNumber(0, icons.length - 1)

  return {
    uid: uid(),

    type: 'item',

    name: 'SocialLinks',

    alias,

    props: { ...config.root.props },

    settings: { ...config.root.settings, ...settings },

    content: {},
  }
}
