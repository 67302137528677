import { FaqItem } from '../items/FaqItem'

import uid from '@/utils/uid'

import getDummyText from '@/utils/getDummyText'
import { align, bgColor, boxShadow, fontSize, width } from '@/config/props'

export const config = {
  title: 'Faq',

  root: {
    settings: {
      type: null,
    },

    props: {
      width: width(),
      fontSize: fontSize(),
      align: align('center'),
      bgColor: bgColor(),
      boxShadow: boxShadow(),
      // expand: expand(),
    },

    additionalItems: [
      { label: 'Headline', name: 'Headline', max: 1 },
      { label: 'Intro', name: 'BodyText', max: 1 },
      { label: 'Faq item', name: 'FaqItem' },
    ],
  },

  Headline: {
    additionalItems: [{ label: 'Tagline', name: 'Tagline', max: 1 }],
  },
}

export async function Faq({ settings } = {}) {
  const body = (await getDummyText({ numItems: 3, sents: 3 })) || []

  const headlines =
    (await getDummyText({
      numItems: 3,
      type: 'question',
    })) || []

  const items = await Promise.all(
    new Array(3).fill('').map(async (item, idx) => {
      return await FaqItem({
        bodyContent: body[idx],
        headlineContent: headlines[idx],
      })
    })
  )

  return {
    uid: uid(),

    name: 'Faq',

    type: 'block',

    settings,

    items,

    props: {
      ...config.root.props,
    },
  }
}
