import { Client } from 'faunadb'

import { dispatch } from 'vuex-pathify'
import {
  createUser,
  verifyUser,
  getUser,
  updateUser,
  deleteUser,
  updateUserPassword,
} from './queries/users'

import {
  createPage,
  updatePage,
  deletePage,
  getPageById,
  publishPage,
  unpublishPage,
  getPublishedVersion,
  getPublishedVersions,
  getPublishedPageBySlug,
  getPublishedPageByCustomDomain,
  getFormSubmissionsByPageId,
} from './queries/pages'

import {
  createSubscription,
  cancelSubscription,
  resumeSubscription,
} from './queries/subscriptions'

import { createToken } from './queries/tokens'

export class DbService {
  constructor(guestSecret, authState, redirect) {
    this.guestSecret = guestSecret
    this.authState = authState
    this.redirect = redirect
  }

  async query(query, asGuest = false) {
    const secret = asGuest
      ? this.guestSecret
      : this.authState.token ?? this.guestSecret

    const client = new Client({
      secret,
    })

    try {
      return await client.query(query)
    } catch (e) {
      // DB returned error
      // Check if it is a 401 - redirect to start
      // Else - rethrow error to let it bubble
      const {
        requestResult: { statusCode },
      } = e

      if (statusCode === 401) {
        await dispatch('auth/logout')
        return this.redirect('/?loggedOut=1')
      }

      throw e
    }
  }

  // USERS
  createUser(payload) {
    return this.query(createUser(payload))
  }

  verifyUser(token) {
    return this.query(verifyUser(token))
  }

  getUser() {
    return this.query(getUser())
  }

  updateUser(data) {
    return this.query(updateUser(data))
  }

  updateUserPassword(data) {
    return this.query(updateUserPassword(data))
  }

  deleteUser() {
    return this.query(deleteUser())
  }

  // PAGES

  createPage(payload) {
    return this.query(createPage(payload))
  }

  updatePage(id, payload) {
    return this.query(updatePage(id, payload))
  }

  deletePage(id) {
    return this.query(deletePage(id))
  }

  getPageById(id, userId) {
    return this.query(getPageById(id, userId))
  }

  getFormSubmissionsByPageId(id) {
    return this.query(getFormSubmissionsByPageId(id))
  }

  // SUBSCRIPTIONS

  createSubscription(payload) {
    return this.query(createSubscription(payload))
  }

  cancelSubscription(id, cancelAt) {
    return this.query(cancelSubscription(id, cancelAt))
  }

  resumeSubscription(id) {
    return this.query(resumeSubscription(id))
  }

  // PUBLISHED PAGE

  publishPage(payload) {
    return this.query(publishPage(payload))
  }

  unpublishPage(id) {
    return this.query(unpublishPage(id))
  }

  getPublishedVersion(id) {
    return this.query(getPublishedVersion(id))
  }

  getPublishedVersions(pageId) {
    return this.query(getPublishedVersions(pageId))
  }

  getPublishedPageBySlug(slug) {
    return this.query(getPublishedPageBySlug(slug), true)
  }

  getPublishedPageByCustomDomain(domain) {
    return this.query(getPublishedPageByCustomDomain(domain), true)
  }

  // TOKENS

  createToken(payload) {
    return this.query(createToken(payload))
  }
}
