import uid from '@/utils/uid'
import getDummyText from '@/utils/getDummyText'
import { bgColor, fontSize } from '~/config/props'

export const config = {
  title: 'Header / Body text',
  root: {
    props: {
      bgColor: bgColor(),
      fontSize: fontSize(),
    },
  },

  editor: {
    extensions: [
      ['Heading', ['levels', [3]]],
      'HardBreak',
      'Link',
      'Bold',
      'Italic',
      'Underline',
      'BulletList',
      'OrderedList',
      'ListItem',
    ],
  },
}

export async function HeaderBodyText({ content = '', alias = 'body' } = {}) {
  if (!content) {
    content = await getDummyText({
      numItems: 2,
      sents: 2,
      asArray: false,
      html: true,
    })
  }

  return {
    uid: uid(),

    type: 'item',

    name: 'HeaderBodyText',

    alias,

    props: { ...config.root.props },

    content: {
      html: content,
    },
  }
}
