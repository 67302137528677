import { make } from 'vuex-pathify' // eslint-disable-line
import state from './state'

export default {
  ...make.mutations(state),

  REMOVE_PAGE(state, id) {
    state.data.pages = state.data.pages.filter(item => item.id !== id)
  },

  UPDATE_DATA(state, data) {
    state.data = { ...state.data, ...data }
  },
}
