import { Headline } from './Headline'
import { BodyText } from './BodyText'
import uid from '@/utils/uid'

export const config = {
  title: 'Faq item',

  root: {
    props: {},
  },

  isSortable: true,

  editor: {
    extensions: [
      'HardBreak',
      'Link',
      'Bold',
      'Italic',
      'BulletList',
      'ListItem',
    ],
  },
}

export async function FaqItem({
  headlineContent,
  bodyContent,
  alias = 'faqItem',
} = {}) {
  return {
    uid: uid(),

    type: 'item',

    name: 'FaqItem',

    alias,

    props: { ...config.root.props },

    items: [
      await Headline({
        weight: 'h3',
        content: headlineContent,
        type: 'question',
      }),
      await BodyText({ content: bodyContent }),
    ],
  }
}
