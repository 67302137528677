import { Headline } from '../items/Headline'

import uid from '@/utils/uid'
import { boxShadow } from '~/config/props'

export const config = {
  title: 'Page header',

  allowedOnce: true,
  canBeCombined: false,
  canBeDuplicated: false,
  canHaveBgImage: false,
  canBeMoved: false,
  canChangeHeight: false,

  root: {
    hiddenProps: ['width'],

    props: {
      width: '1:1',
      boxShadow: boxShadow(),
    },

    additionalItems: [
      {
        label: 'Logo',
        name: 'ImageItem',
        max: 1,
        alias: 'logo',
        opts: {
          insertTypes: ['upload'],
        },
      },
      { label: 'Navigation', name: 'Navigation', max: 1 },
      // { label: 'Button', name: 'Btn', max: 2 },
      {
        label: 'Social links',
        name: 'SocialLinks',
        max: 1,
      },
    ],
  },
}

export async function PageHeader() {
  return {
    uid: uid(),

    name: 'PageHeader',

    type: 'block',

    props: {
      ...config.root.props,
    },

    items: [
      await Headline({
        weight: 'h1',
        content: 'Company name',
      }),
    ],
  }
}
