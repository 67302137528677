import { get } from 'vuex-pathify'

export default {
  head() {
    const h = {
      htmlAttrs: {
        prefix: 'og: http://ogp.me/ns#',
      },
      title: this.pageTitle,
      meta: [
        {
          name: 'robots',
          content: this.noIndexing ? 'noindex' : 'index',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:title',
          content: this.pageTitle,
        },
        {
          property: 'twitter:title',
          content: this.pageTitle,
        },
        {
          property: 'og:url',
          content: this.pageUrl,
        },
      ],
      link: [{ rel: 'canonical', href: this.pageUrl }],
    }

    if (this.metaDesc) {
      h.meta.push(
        ...[
          {
            name: 'description',
            content: this.metaDesc,
          },
          {
            property: 'og:description',
            content: this.metaDesc,
          },
          {
            property: 'twitter:description',
            content: this.metaDesc,
          },
        ]
      )
    }

    if (this.umami) {
      h.script = [
        {
          'data-website-id': this.umami.uuid,
          src: this.$config.umamiScriptUrl,
          async: true,
          defer: true,
        },
      ]
    }

    return h
  },

  computed: {
    pageSlug: get('page/obj@slug'),
    pageTitle: get('page/obj@title'),
    metaDesc: get('page/obj@metadata.metaDescription'),
    noIndexing: get('page/obj@metadata.noIndexing'),
    customDomainStatus: get('page/customDomainStatus'),
    customDomainName: get('page/customDomainName'),
    umami: get('page/obj@umami'),

    pageUrl() {
      if (this.customDomainStatus === 'active') {
        return `https://${this.customDomainName}`
      }
      return `https://${this.pageSlug}.${this.$config.appDomain}`
    },
  },
}
