import uid from '@/utils/uid'
import { effect } from '@/config/props'

export const config = {
  title: 'Map',

  root: {
    props: {
      effect: effect(),
    },

    hideSettings: true,

    settings: {
      zoom: 14,
      coordinates: null,
      loading: false,
    },

    opts: {
      effect: {
        none: 'None',
        light: 'Light',
        dark: 'Dark',
        retro: 'Retro',
        night: 'Night',
        twilight: 'Twilight',
      },
    },
  },
}

export function Map({ alias = 'map' } = {}) {
  return {
    uid: uid(),

    type: 'item',

    name: 'Map',

    alias,

    props: { ...config.root.props },

    settings: { ...config.root.settings },
  }
}
