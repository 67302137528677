import uid from '@/utils/uid'
import { bgColor, fontSize } from '~/config/props'
// import icons from '@/config/icons'
// import randomNumber from '~/utils/randomNumber'

export const config = {
  title: 'Rich list',

  root: {
    props: {
      bgColor: bgColor(),
      fontSize: fontSize(),
    },
    settings: {
      items: null,
    },
  },
}

export function RichList({ settings, alias = 'richList' } = {}) {
  // const randomIconIndex = randomNumber(0, icons.length - 1)

  return {
    uid: uid(),

    type: 'item',

    name: 'RichList',

    alias,

    props: { ...config.root.props },

    settings: { ...config.root.settings, ...settings },

    content: {},
  }
}
