import { make } from 'vuex-pathify'
import uid from '~/utils/uid'

export const state = () => ({
  token: null,
})

export const mutations = {
  ...make.mutations(state),
}

export const actions = {
  async register({ commit, dispatch }, postData) {
    const token = uid()
    const payload = { ...postData, ...{ token } }

    const data = await this.$db.createUser(payload)

    commit('user/SET_DATA', data, { root: true })

    try {
      dispatch('sendVerificationEmail', token)
    } catch (e) {}
  },

  async login({ commit, state }, credentials) {
    const { token } = await this.$api('/login', credentials)

    commit('SET_TOKEN', token)
  },

  async logout({ commit }) {
    await this.$api('/logout')

    commit('user/SET_DATA', {}, { root: true })
    commit('SET_TOKEN', null)
    commit('page/RESET', null, { root: true })
  },

  async verify({ rootState, dispatch }) {
    if (!rootState.user.data.id) {
      throw new Error('No user')
    }

    const token = uid()

    await this.$db.createToken({
      type: 'verifyUser',
      userId: rootState.user.data.id,
      token,
    })

    dispatch('sendVerificationEmail', token)
  },

  async sendVerificationEmail({ rootState }, token) {
    await this.$api('/emails/verify', {
      recipientEmail: rootState.user.data.email,
      token,
    })
  },
}

export const getters = {
  isLoggedIn(state, getters, rootState) {
    return state.token && rootState.user.data.id !== undefined
  },

  isSuperAdmin(state) {
    return false
  },
}
