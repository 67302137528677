import { SubmitBtn } from './SubmitBtn'
import { TextField } from './TextField'

import uid from '@/utils/uid'

export const config = {
  title: 'Email form',

  pro: true,

  root: {
    props: {},

    settings: {
      formName: null,
      recipient: null,
      successMsg: null,
    },
  },
}

export function EmailForm({ settings, alias = 'emailForm' } = {}) {
  return {
    uid: uid(),

    type: 'item',

    name: 'EmailForm',

    alias,

    pro: true,

    items: [
      TextField({ inputType: 'email', placeholder: 'Your email address' }),
      SubmitBtn({ label: 'Send', settings: {} }),
    ],

    props: { ...config.root.props },

    settings: { ...config.root.settings, ...settings },

    content: {},
  }
}
