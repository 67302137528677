import { ImageItem } from './ImageItem'
import { PlainText } from './PlainText'
import getDummyText from '@/utils/getDummyText'

import uid from '@/utils/uid'
import { appearance, bgColor, boxShadow } from '@/config/props'

export const config = {
  title: 'Quote',

  isSortable: true,

  root: {
    props: {
      bgColor: bgColor(),
      boxShadow: boxShadow(),
    },

    additionalItems: [
      { label: 'Face', name: 'ImageItem', max: 1, alias: 'face' },
      {
        label: 'Name',
        name: 'PlainText',
        max: 1,
        alias: 'name',
        placeholder: '<p>Jane Doe</p>',
      },
      {
        label: 'Title',
        name: 'PlainText',
        max: 1,
        alias: 'title',
        placeholder: '<p>Lead developer</p>',
      },
    ],
  },

  ImageItem: {
    dimensions: {
      width: 400,
      height: 400,
    },

    props: {
      appearance: appearance('shape@circle'),
    },

    opts: {
      appearance: {
        shape: 'Shape',
        blob: 'Blob',
      },
    },
  },
}

export async function Quote({ bodyContent, alias = 'quote' } = {}) {
  if (!bodyContent) {
    bodyContent = await getDummyText({
      paras: 1,
      sents: 3,
      asArray: false,
      html: true,
    })
  }

  return {
    uid: uid(),

    type: 'item',

    name: 'Quote',

    alias,

    props: { ...config.root.props },

    items: [
      PlainText({ content: bodyContent, alias: 'quote' }),

      ImageItem({
        alias: 'face',
        settings: {
          url: 'https://images.unsplash.com/photo-1469334031218-e382a71b716b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&h=400&q=80',
          height: 400,
          width: 400,
        },

        props: {
          appearance: 'shape@circle',
        },
      }),

      PlainText({ content: '<p>Jane Doe</p>', alias: 'name' }),
      PlainText({ content: '<p>Lead developer</p>', alias: 'title' }),
    ],
  }
}
