import { query } from 'faunadb'
import { createToken } from './tokens'
const {
  If,
  Do,
  Let,
  Var,
  Get,
  Now,
  Map,
  Match,
  Index,
  Abort,
  Update,
  Select,
  Delete,
  Merge,
  Create,
  Lambda,
  Paginate,
  Identify,
  Collection,
  CurrentIdentity,
} = query

function createUser({ agreed, email, name, password, token }) {
  return Let(
    {
      user: Create(Collection('Users'), {
        data: {
          createdAt: Now(),
          agreed,
          email,
          name,
        },

        credentials: {
          password,
        },
      }),
      userId: Select(['ref', 'id'], Var('user')),
    },
    Do(
      createToken({
        userId: Var('userId'),
        type: 'verifyUser',
        token,
      }),
      Merge({ id: Var('userId') }, Select('data', Var('user')))
    )
  )
}

function verifyUser(token) {
  // Get token doc
  return Let(
    {
      token: Get(Match(Index('Token_by_token'), token)),
      userRef: Select(['data', 'userRef'], Var('token')),
    },

    // Update user with emailIsVerified if token is found
    Do(
      Update(Var('userRef'), {
        data: {
          emailIsVerified: true,
        },
      }),

      // Delete token to cleanup
      Delete(Select(['ref'], Var('token'))),

      // Return verified users email
      Select(['data', 'email'], Get(Var('userRef')))
    )
  )
}

function getUser() {
  return Let(
    {
      user: Get(CurrentIdentity()),
    },
    {
      data: Merge(
        Merge(
          { id: Select(['ref', 'id'], Var('user')) },
          Select('data', Var('user'))
        ),
        {
          pages: Select(
            'data',
            Map(
              Paginate(
                Match(Index('Pages_by_userRef'), Select('ref', Var('user')))
              ),
              Lambda(
                'item',
                Let(
                  {
                    page: Get(Var('item')),
                  },
                  Merge(
                    { id: Select(['ref', 'id'], Var('page')) },
                    Select('data', Var('page'))
                  )
                )
              )
            )
          ),
        }
      ),
    }
  )
}

function updateUser(data) {
  return Let(
    {
      user: Update(CurrentIdentity(), {
        data,
      }),
    },
    Select('data', Var('user'))
  )
}

function deleteUser() {
  return Delete(CurrentIdentity())
}

function updateUserPassword({ currentPassword, newPassword }) {
  return Let(
    {
      identity: CurrentIdentity(),
    },
    If(
      Identify(Var('identity'), currentPassword),
      Update(Var('identity'), {
        credentials: {
          password: newPassword,
        },
      }),
      Abort('Invalid current password')
    )
  )
}

export {
  createUser,
  getUser,
  updateUser,
  updateUserPassword,
  verifyUser,
  deleteUser,
}
