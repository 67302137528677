import Vue from 'vue'
import { make, commit, dispatch } from 'vuex-pathify' // eslint-disable-line
import { findAll } from 'obj-traverse/lib/obj-traverse'
import state from './state'
import findFirst from '@/utils/findFirst'
import uid from '@/utils/uid'
import clone from '@/utils/clone'
import defaultPage from '@/config/defaultPage'

import { Section } from '~/models/section'
import { configs as blocksConfigs } from '~/models/blocks/index'
import { configs as itemsConfig } from '~/models/items/index'

const colFractions = {
  '1:1': 3,
  '1:2': 1.5,
  '1:3': 1,
  '2:3': 2,
}
const colFractionsRev = {
  3: '1:1',
  1.5: '1:2',
  1: '1:3',
  2: '2:3',
}

function getSectionBlockRows(parent) {
  return parent.items
    .map(item => ({
      uid: item.uid,
      width: item.props.width,
    }))
    .reduce(
      (prev, item, idx, arr) => {
        const currentRowCount = prev[prev.length - 1]
          .map(([, { width }]) => width)
          .reduce((rowPrev, rowCurr) => rowPrev + rowCurr, 0)

        const itemColWidth = colFractions[item.width]
        const rowIsFull = currentRowCount === 3
        const rowWillOverflow = currentRowCount + itemColWidth > 3

        if (rowIsFull || rowWillOverflow) {
          prev.push([])
        }

        prev[prev.length - 1].push([item.uid, { width: itemColWidth }])
        return prev
      },
      [[]]
    )
    .map(row => {
      return row.map((col, idx, arr) => {
        if (idx === 0 && arr.length === 1) {
          col[1].pos = 'only'
        } else if (idx === 0) {
          col[1].pos = 'first'
        } else if (idx === 1 && arr.length === 2) {
          col[1].pos = 'last'
        }

        return [col[0], col[1]]
      })
    })
}

function findParent(root, itemLookingForParent) {
  let parent = null
  function traverse(root) {
    return (
      root.items &&
      root.items.forEach(rootItem => {
        if (rootItem.uid === itemLookingForParent.uid) {
          parent = root
          return true
        }
        return traverse(rootItem)
      })
    )
  }
  traverse(root)
  return parent
}

function removeItem(state, item) {
  const parent = findParent(state.obj.data, item)

  if (!parent) {
    return false
  }

  parent.items = parent.items.filter(parentItem => parentItem.uid !== item.uid)

  return parent
}

function updateWidth(state, item, key, value) {
  let itemUpdated = false
  if (item.props.width === '1:1' && value !== item.props.width) {
    Vue.set(item.props, key, value)
    itemUpdated = true
  }

  const parent = findParent(state.obj.data, item)
  const { pos } = Object.fromEntries(getSectionBlockRows(parent).flat())[
    item.uid
  ]
  const itemIndex = parent.items.findIndex(({ uid }) => item.uid === uid)
  let siblingIndex
  let sibling
  let siblingWidth

  if (pos === 'first') {
    siblingIndex = itemIndex + 1
  } else if (pos === 'last') {
    siblingIndex = itemIndex - 1
  }

  if (!itemUpdated) {
    Vue.set(item.props, key, value)
  }

  if (siblingIndex !== undefined && value !== '1:1') {
    sibling = parent.items[siblingIndex]
    siblingWidth = 3 - colFractions[value]
    Vue.set(sibling.props, key, colFractionsRev[siblingWidth])
  }
}

function setBlockWidths(existingBlock, newBlock) {
  console.log(existingBlock, newBlock)
  newBlock.props.width = '1:2'

  if (existingBlock.props.width === '1:1') {
    existingBlock.props.width = '1:2'
  } else if (existingBlock.props.width === '2:3') {
    newBlock.props.width = '1:3'
  } else if (existingBlock.props.width === '1:3') {
    newBlock.props.width = '2:3'
  }
}

function syncSiblings(state, item, key, value) {
  let rootParentObj
  let parent
  let needle = item

  while (!rootParentObj) {
    parent = findParent(state.obj.data, needle)
    if (parent.type !== 'block') {
      needle = parent
    } else {
      rootParentObj = parent
    }
  }

  const siblings = findAll(rootParentObj, 'items', { name: item.name })

  if (siblings) {
    siblings.forEach(sibling => {
      Vue.set(sibling.props, key, value)
    })
  }
}

export default {
  ...make.mutations(state),

  RESET(state) {
    state.obj = { data: defaultPage() }
    state.saving = false
    state.revPointer = 0
  },

  DECREASE_REV_POINTER(state) {
    state.revPointer--
  },

  INCREASE_REV_POINTER(state) {
    state.revPointer++
  },

  SET_OBJ_PROPS(state, props) {
    Object.keys(props).forEach(key => {
      Vue.set(state.obj, key, props[key])
    })
  },

  SET_OBJ_DATA(state, { value = {} }) {
    state.obj.data = value
  },

  SET_OBJ_SLUG(state, value) {
    Vue.set(state.obj, 'slug', value)
    // state.obj.subscription = value
  },

  SET_OBJ_SUBSCRIPTION(state, value) {
    Vue.set(state.obj, 'subscription', value)
  },

  SET_OBJ_DATA_ITEMS(state, { value = [] }) {
    state.obj.data.items = value
  },

  SET_UPDATED_AT(state, value) {
    Vue.set(state.obj, 'updatedAt', value)
  },

  SET_PUBLISHED_AT(state, value) {
    Vue.set(state.obj, 'publishedAt', value)
  },

  SET_OBJ_DATA_PROPS(state, { value }) {
    console.log(value)
    if (!state.obj.data.props) {
      Vue.set(state.obj.data, 'props', {})
    }

    state.obj.data.props = { ...state.obj.data.props, ...value }
  },

  SET_OBJ_DATA_SETTINGS(state, { value }) {
    state.obj.data.settings = { ...state.obj.data.settings, ...value }
  },

  SET_OBJ_DATA_METADATA(state, { value }) {
    if (!state.obj.data.metadata) {
      Vue.set(state.obj.data, 'metadata', { gaId: null, fbPixel: null })
    }

    state.obj.data.metadata = { ...state.obj.data.metadata, ...value }
  },

  SET_BLOCK_ITEMS(state, { item, value }) {
    // Instead of just replacing items..
    // First remove the existing ones, with the same uids as in the items in value
    // Then merge in the new ones. This way we can keep the additionalItem (if any)
    const obj = findFirst(state.obj.data, 'items', { uid: item.uid })
    const ids = value.map(item => item.uid)

    const itemsToKeep = obj.items.filter(item => !ids.includes(item.uid))
    obj.items = [...itemsToKeep, ...value]
  },

  ADD_ADDITIONAL_ITEM(state, { parent, item }) {
    const parentObj = findFirst(state.obj.data, 'items', { uid: parent.uid })

    if (!parentObj.items) {
      Vue.set(parentObj, 'items', [])
    }

    // Add item props from parent config, if any
    const conf = parentObj.type === 'block' ? blocksConfigs : itemsConfig

    const props = clone(conf[parentObj.name]?.[item.name]?.props ?? {})

    if (Object.keys(props).length) {
      item.props = props
    }

    // if custom item is a Feature, set icon bg based on sibling's setting
    if (item.name === 'Feature') {
      const siblingIcon = findFirst(parentObj, 'items', { name: 'Icon' })
      if (siblingIcon) {
        const newIcon = item.items.find(item => item.name === 'Icon')
        if (newIcon) {
          newIcon.props.bgColorBool = siblingIcon.props.bgColorBool
        }
      }
    }

    parentObj.items.push(item)

    // Set full page loading if block contains image
    if (['ImageBlock', 'Cards'].includes(item.name)) {
      commit('SET_FULL_PAGE_LOADING', 'Image loading…')
    }
  },

  REMOVE_ITEM(state, { item }) {
    // Find parent of item to delete
    removeItem(state, item)
  },

  UPDATE_ITEM_CONTENT(state, { item, value }) {
    const obj = findFirst(state.obj.data, 'items', { uid: item.uid })
    if (obj) {
      obj.content.html = value
    }
  },

  UPDATE_ITEM_PROP(state, { item, key, value, updateSiblings }) {
    if (key === 'width') {
      updateWidth(state, item, key, value)
    } else {
      Vue.set(item.props, key, value)
    }

    // If has siblings also update setting for siblings.. they need to be in sync
    if (updateSiblings && item.type === 'item') {
      syncSiblings(state, item, key, value)
    }
  },

  UPDATE_ITEM_SETTINGS(state, { item, value }) {
    const obj = findFirst(state.obj.data, 'items', { uid: item.uid })

    if (!obj) {
      return false
    }

    Vue.set(obj, 'settings', value)
  },

  UPDATE_ITEM_SETTING(state, { item, key, value }) {
    const obj = findFirst(state.obj.data, 'items', { uid: item.uid })

    if (!obj) {
      return false
    }

    if (!obj.settings) {
      Vue.set(obj, 'settings', {})
    }

    Vue.set(obj.settings, key, value)
  },

  CHANGE_PARENT(state, { targetParent, item } = {}) {
    const itemClone = clone(item)

    const parentOfRemovedItem = removeItem(state, item)
    const indexOfParentOfRemovedItem = state.obj.data.items.findIndex(
      item => item.uid === parentOfRemovedItem.uid
    )

    if (targetParent) {
      setBlockWidths(targetParent.items[0], itemClone)
      targetParent.items.push(itemClone)
    } else {
      const section = Section({ items: [itemClone] })
      // Insert new section after the section the item was taken from
      state.obj.data.items.splice(indexOfParentOfRemovedItem + 1, 0, section)
    }

    // Kill parent of item to move, if no children left
    if (!parentOfRemovedItem.items?.length) {
      state.obj.data.items = state.obj.data.items.filter(
        item => item.uid !== parentOfRemovedItem.uid
      )
    }
  },

  DUPLICATE_ITEM(state, { item, isSection }) {
    const obj = findFirst(state.obj.data, 'items', { uid: item.uid })
    const parent = isSection ? state.obj.data : findParent(state.obj.data, item)

    const idx = parent.items.indexOf(obj)
    const newObj = clone(obj)

    newObj.uid = uid()

    // Recursively replace all ids
    function replaceIds(obj) {
      obj.items?.forEach(item => {
        item.uid = uid()
        if (item.items) {
          replaceIds(item)
        }
      })
    }

    replaceIds(newObj)

    if (item.type === 'block') {
      const sibling = parent.items[0]

      newObj.props.width = '1:2'
      sibling.props.width = '1:2'
    }

    parent.items.splice(idx + 1, 0, newObj)
  },

  ADD_SECTION(state, { value: { sectionItems } }) {
    const section = Section({ items: sectionItems })
    const numBlocks = section.items.length

    if (numBlocks > 1) {
      section.items.map((item, idx) => {
        item.props.width =
          idx % 2 === 0 && idx === numBlocks - 1 ? '1:1' : '1:2'
        return item
      })
    }

    // Set full page loading if block contains image
    sectionItems
      .map(item => item.name)
      .forEach(type => {
        if (['ImageBlock', 'Cards'].includes(type)) {
          commit('SET_FULL_PAGE_LOADING', 'Image loading…')
        }
      })

    // Add to top of page if InfoBar
    if (sectionItems[0].name === 'InfoBar') {
      state.obj.data.items.unshift(section)
    } else if (sectionItems[0].name === 'PageHeader') {
      // Add below InfoBar if it exists, else add to to top
      const pageHasInfoBar = state.obj.data.items.some(
        item => item.items[0].name === 'InfoBar'
      )

      if (pageHasInfoBar) {
        state.obj.data.items.splice(1, 0, section)
      } else {
        state.obj.data.items.unshift(section)
      }
    } else {
      state.obj.data.items.push(section)
    }
  },

  ADD_BLOCK_TO_SECTION(state, { value: { parentId, block } }) {
    const parent = findFirst(state.obj.data, 'items', { uid: parentId })

    const existingLastBlock = parent.items[0]

    setBlockWidths(existingLastBlock, block)

    parent.items.push(block)

    // Set full page loading if block contains image
    if (['ImageBlock', 'Cards'].includes(block.name)) {
      commit('SET_FULL_PAGE_LOADING', 'Image loading…')
    }
  },

  MOVE_SECTION(state, { item, from, to }) {
    state.obj.data.items.splice(to, 0, state.obj.data.items.splice(from, 1)[0])
  },

  REMOVE_SECTION(state, { value }) {
    state.obj.data.items = state.obj.data.items.filter(
      item => item.uid !== value.uid
    )
  },

  UPDATE_SECTION_PROP(state, { value }) {
    const section = state.obj.data.items.find(item => item.uid === value.uid)
    Vue.set(section, value.key, value.data)
  },
}
