import { PlainText } from '../items/PlainText'
import { Btn } from '../items/Btn'

import uid from '@/utils/uid'
import { fontSize } from '~/config/props'

export const config = {
  title: 'Infobar',

  allowedOnce: true,
  canBeCombined: false,
  canBeDuplicated: false,
  canHaveBgImage: false,
  canBeMoved: false,
  canChangeHeight: false,

  root: {
    hiddenProps: ['width'],

    props: {
      fontSize: fontSize(),
      width: '1:1',
    },

    additionalItems: [
      { label: 'Button', name: 'Btn' },
      { label: 'Icon', name: 'Icon', max: 1 },
    ],
  },
}

export function InfoBar() {
  return {
    uid: uid(),

    name: 'InfoBar',

    type: 'block',

    props: {
      ...config.root.props,
    },

    items: [
      PlainText({
        content: '<p>Some info text or important message</p>',
        alias: 'name',
      }),
      Btn(),
    ],
  }
}
