import uid from '@/utils/uid'

export const config = {
  title: 'Submit button',

  editor: { hide: true, extensions: [] },
  root: {
    props: {},
  },
}

export function SubmitBtn({
  label = 'This is a button',
  alias = 'submitBtn',
} = {}) {
  return {
    uid: uid(),

    type: 'item',

    name: 'SubmitBtn',

    alias,

    props: { ...config.root.props },

    content: {
      html: label,
    },
  }
}
