import uid from '@/utils/uid'

export const config = {
  title: 'Navigation',

  root: {
    settings: {},
    props: {},
  },
}

export function Navigation({ settings }) {
  return {
    uid: uid(),

    type: 'item',

    name: 'Navigation',

    props: { ...config.root.props },

    settings: { ...config.root.settings, ...settings },
  }
}
