import uid from '@/utils/uid'
import getDummyText from '@/utils/getDummyText'
import { fontSize, gradient } from '~/config/props'

export const config = {
  title: 'Headline',

  editor: {
    hide: ['heading'],
    extensions: ['Heading', 'NoBreakLine', 'Underline'],
  },

  root: {
    props: {
      fontSize: fontSize(),
      gradient: gradient(),
    },
    additionalItems: [{ label: 'Tagline', name: 'Tagline', max: 1 }],
  },
}

export async function Headline({
  weight = 'h2',
  content = '',
  type = 'headline',
  alias = 'headline',
} = {}) {
  if (!content) {
    content = await getDummyText({
      numItems: 1,
      type,
    })
  }

  return {
    uid: uid(),

    type: 'item',

    name: 'Headline',

    alias,

    weight,

    props: { ...config.root.props },

    items: [],

    content: {
      html: `<${weight}>${content}</${weight}>`,
    },
  }
}
