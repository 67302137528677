import { InfoBar, config as InfoBarConf } from './InfoBar'
import { Content, config as ContentConf } from './Content'
import { Features, config as FeaturesConf } from './Features'
import { Cards, config as CardsConf } from './Cards'
import { MapBlock, config as MapBlockConf } from './MapBlock'
import { EmailForm, config as EmailFormConf } from './EmailForm'
import { Embed, config as EmbedConf } from './Embed'
import { Faq, config as FaqConf } from './Faq'
import { Quotes, config as QuotesConf } from './Quotes'
import { HorizontalLine, config as HorizontalLineConf } from './HorizontalLine'
import { ImageBlock, config as ImageBlockConf } from './ImageBlock'
import { PageHeader, config as PageHeaderConf } from './PageHeader'

export const configs = {
  PageHeader: PageHeaderConf,
  Content: ContentConf,
  HorizontalLine: HorizontalLineConf,
  ImageBlock: ImageBlockConf,
  Features: FeaturesConf,
  Cards: CardsConf,
  Faq: FaqConf,
  Quotes: QuotesConf,
  EmailForm: EmailFormConf,
  Embed: EmbedConf,
  MapBlock: MapBlockConf,
  InfoBar: InfoBarConf,
}

export const blocks = {
  PageHeader,
  Content,
  Features,
  Cards,
  Faq,
  Quotes,
  EmailForm,
  Embed,
  HorizontalLine,
  MapBlock,
  ImageBlock,
  InfoBar,
}
