import pathify, { dispatch } from 'vuex-pathify' // eslint-disable-line
import { make, commit } from 'vuex-pathify' // eslint-disable-line

pathify.options.deep = 2

export const plugins = [pathify.plugin]

export const state = () => ({
  unsplashKey: null,
  isAdminMode: false,
  previewMode: null,
  loadAsPreview: false,
  isPublicPage: false,
  isStartingPointPreview: false,
  activeSectionUid: null,
  fontsLoading: false,
  fullPageLoading: null,
  numOpenModals: 0,
  editorActive: false,
  changeParentForItem: null,
  faqFormIsDirty: false,
  confirmFaqFormCancel: false,
  stripeIsLoaded: false,
  pageHasUnsavedChanges: false,
})

export const mutations = {
  ...make.mutations(state),

  INCREASE_NUM_OPEN_MODALS(state) {
    state.numOpenModals++
  },

  DECREASE_NUM_OPEN_MODALS(state) {
    state.numOpenModals--
  },
}

export const actions = {
  async getStartingPointPreview({ commit }, { host, error }) {
    const key = host.split('.')[0].substr(3)
    try {
      const { data } = await this.$content('startingPoints', key).fetch()
      commit('page/SET_OBJ_DATA', { value: data })
      commit('page/SET_OBJ_SLUG', { value: data })
    } catch (e) {
      return error({
        statusCode: 404,
        message: 'Page not found',
      })
    }
  },

  async handlePublicPage({ state, dispatch }, { host, error, redirect }) {
    // If page has customdomain but is accessed on siddler domain - redirect
    if (
      state.page.obj.customDomain?.status === 'active' &&
      host.endsWith(this.$config.appDomain)
    ) {
      return redirect(301, state.page.obj.customDomain.name)
    }

    await dispatch('getPublicPage', { host, error })
  },

  async getPublicPage({ commit, dispatch, state }, { host, error }) {
    try {
      await dispatch('page/getPublicPage', host)
    } catch (e) {
      return error({
        statusCode: 404,
        message: 'Page not found 😕',
      })
    }
  },

  setAuthToken(ctx, { headers }) {
    const cookies = headers.cookie

    if (!cookies) {
      return
    }

    const { authToken } = Object.fromEntries(
      cookies.split(';').map(item => {
        const [key, value] = item.split('=')
        return [key.trim(), value.trim()]
      })
    )

    // Commit auth token to store if any
    if (authToken) {
      commit('auth/SET_TOKEN', authToken)
    }
  },

  async nuxtServerInit(
    { commit, dispatch, state },
    { route, req, redirect, $config, error }
  ) {
    const { host } = req.headers

    const subdomain = host.split('.')[0]

    // Is admin
    if (subdomain === 'app') {
      dispatch('setAuthToken', req)
      commit('SET_LOAD_AS_PREVIEW', route.query.preview !== undefined)
      commit('SET_IS_ADMIN_MODE', route.query.preview === undefined)
      commit('SET_UNSPLASH_KEY', $config.unsplashAccessKey)
      // Is public page
    } else {
      commit('SET_IS_PUBLIC_PAGE', true)

      // Get starting point
      if (subdomain.substr(0, 3) === 'sp-') {
        await dispatch('getStartingPointPreview', { host, error })
        // Get public page
      } else {
        await dispatch('handlePublicPage', { host, error, redirect })
      }
    }
  },
}
