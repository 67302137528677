import lstore from 'store'

export default {
  init({ dispatch, getters }) {
    if (lstore.get(getters.storageKey) === undefined) {
      dispatch('save')
    }
  },

  setBtnStates({ getters, commit }) {
    if (!process.browser) {
      return
    }

    const revs = lstore.get(getters.storageKey)

    if (!revs) {
      return
    }

    const { data, pointer } = revs

    const hasHistory = data.length > 1

    commit(
      'SET_CAN_UNDO',
      hasHistory && (pointer === 0 || data.length > pointer + 1)
    )

    commit('SET_CAN_REDO', pointer > 0 && hasHistory)
  },

  save({ rootState, getters, dispatch, rootGetters }) {
    if (!rootGetters['auth/isLoggedIn']) {
      return false
    }

    const revLimit = 10
    const history = lstore.get(getters.storageKey) || { data: [], pointer: 0 }
    const { data } = history
    let { pointer } = history

    // If we are saving somewhere in middle of history
    // Remove the array items which are after the pointer by setting the length of the array
    if (pointer > 0) {
      data.length = data.length - pointer
      pointer = 0
    }

    data.push(rootState.page.obj.data)

    // Remove earliest rev if we have reached the limit
    if (data.length > revLimit) {
      data.shift()
    }

    try {
      lstore.set(getters.storageKey, { data, pointer })
    } catch (error) {
      console.log(error)
    }

    dispatch('setBtnStates')
  },

  async undo({ state, commit, getters, dispatch }) {
    const revs = lstore.get(getters.storageKey)

    const { data, pointer } = revs

    // We have reached the earliest rev - no more to undo
    if (!state.canUndo || state.isChanging) {
      return false
    }

    commit('SET_IS_CHANGING', true)

    const revToUse = data[data.length - 2 - pointer]

    commit('page/SET_OBJ_DATA', { value: revToUse }, { root: true })
    await dispatch('saveSite', revToUse)

    // Decrease pointer
    revs.pointer = pointer + 1
    lstore.set(getters.storageKey, revs)

    dispatch('setBtnStates')

    commit('SET_IS_CHANGING', false)

    return true
  },

  async redo({ state, commit, getters, dispatch }) {
    const revs = lstore.get(getters.storageKey)
    const { data, pointer } = revs

    // We have reached the latest rev - no more to redo
    if (!state.canRedo || state.isChanging) {
      return false
    }

    commit('SET_IS_CHANGING', true)

    const revToUse = data[data.length - pointer]

    commit('page/SET_OBJ_DATA', { value: revToUse }, { root: true })
    await dispatch('saveSite', revToUse)

    // increase pointer
    revs.pointer = pointer - 1
    lstore.set(getters.storageKey, revs)

    dispatch('setBtnStates')

    commit('SET_IS_CHANGING', false)

    return true
  },

  async saveSite({ dispatch }, data) {
    try {
      await dispatch('page/save', { data }, { root: true })
    } catch (error) {
      console.log(error)
    }
  },
}
