export const bgColor = ({ color = null } = {}) => {
  return { color }
}

export const bgPattern = ({ name = null } = {}) => {
  return { name }
}

export const bgColorBool = (value = false) => {
  return value
}

export const gradient = (value = false) => {
  return value
}

export const boxShadow = (value = false) => {
  return value
}

export const width = (value = '1:1') => {
  return value
}

export const align = (val = 'left') => {
  return val
}

export const appearance = (val = 'org') => {
  return val
}

export const effect = (val = 'none') => {
  return val
}

export const imgCrop = (val = 'center') => {
  return val
}

export const hrStyle = (
  val = { style: 'solid', width: '1:2', height: 'thin' }
) => {
  return val
}

export const height = (val = '10px') => {
  return val
}

export const coordinates = (val = null) => {
  return val
}

export const fontSize = (val = 'md') => {
  return val
}

export const expand = (val = false) => {
  return val
}

export const direction = (val = 'vert') => {
  return val
}
