import Vue from 'vue'

import { get } from 'vuex-pathify'

Vue.component('IsAdmin', {
  name: 'IsAdmin',

  computed: {
    isAdminMode: get('isAdminMode'),
  },

  render(h) {
    if (!this.isAdminMode) {
      return h(false)
    }
    return this.$slots.default
  },
})

Vue.component('IsGuest', {
  name: 'IsGuest',

  computed: {
    isAdminMode: get('isAdminMode'),
  },

  render(h) {
    if (this.isAdminMode) {
      return h(false)
    }
    return this.$slots.default
  },
})
