import { Feature } from '../items/Feature'

import { align, bgColor, boxShadow, fontSize, width } from '@/config/props'
import getDummyText from '@/utils/getDummyText'
import uid from '@/utils/uid'

export const config = {
  title: 'Features',

  root: {
    props: {
      width: width(),
      fontSize: fontSize(),
      align: align('left'),
      bgColor: bgColor(),
      boxShadow: boxShadow(),
      // expand: expand(),
    },

    additionalItems: [
      { label: 'Headline', name: 'Headline', max: 1 },
      { label: 'Intro', name: 'BodyText', max: 1 },
      { label: 'Feature item', name: 'Feature' },
    ],
  },
}

export async function Features() {
  const body = (await getDummyText({ numItems: 3, sents: 3 })) || []

  const headlines =
    (await getDummyText({
      numItems: 3,
      type: 'headline',
    })) || []

  const items = await Promise.all(
    new Array(3).fill('').map(async (item, idx) => {
      return await Feature({
        bodyContent: body[idx],
        headlineContent: headlines[idx],
      })
    })
  )

  return {
    uid: uid(),

    name: 'Features',

    type: 'block',

    items,

    props: {
      ...config.root.props,
    },
  }
}
