import { get, commit } from 'vuex-pathify'
import vhCheck from 'vh-check'
import fonts from '@/config/fonts'

const WebFont = process.browser ? require('webfontloader') : null
export default {
  head() {
    return {
      bodyAttrs: {
        style: `background:${this.backgroundColor}`,
      },
    }
  },

  computed: {
    pageFont: get('page/obj@data.settings.font'),
    customColors: get('page/obj@data.settings.customColors'),

    backgroundColor() {
      // if (this.customColors) {
      //   return this.customColors.background
      // }

      return '#fff'
    },
  },

  watch: {
    pageFont(val) {
      this.loadFont(val)
    },
  },

  mounted() {
    vhCheck()

    this.loadFont('inter')

    if (this.pageFont !== 'inter') {
      this.loadFont(this.pageFont)
    }
  },

  methods: {
    loadFont(font = 'inter') {
      if (!fonts[font]) {
        return false
      }

      commit('SET_FONTS_LOADING', true)

      const conf = {
        ...fonts[font].loader,
        ...{
          active() {
            commit('SET_FONTS_LOADING', false)
          },
          inactive() {
            commit('SET_FONTS_LOADING', false)
          },
        },
      }

      try {
        WebFont.load(conf)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
