import dayjs from 'dayjs'
import defaultPage from '@/config/defaultPage'

export default {
  isPro(state) {
    return state.obj.subscription?.status === 'active'
  },

  bgColor(state) {
    return state.obj.data.props?.bgColor ?? {}
  },

  items(state) {
    const { items } = state.obj.data

    if (!items) {
      return []
    }

    return items.map(section => {
      section.items.map((block, idx, arr) => {
        if (!block.props.width) {
          block.props.width = arr.length === 2 ? '1:2' : '1:1'
        }
        return block
      })
      return section
    })
  },

  hasUnpublishedChanges(state) {
    const { publishedAt, updatedAt } = state.obj

    // If not updatedAt, page has no data
    if (!updatedAt) {
      return false
    }

    // Page has never been published
    if (!publishedAt) {
      return true
    }

    const updated = dayjs(updatedAt.value)
    const published = dayjs(publishedAt.value)

    return updated.isAfter(published)
  },

  hasRoundedCorners(state) {
    return (
      state.obj.data.settings?.roundedCorners ??
      defaultPage().settings.roundedCorners
    )
  },

  btnShape(state) {
    return state.obj.data.settings?.btnShape ?? defaultPage().settings.btnShape
  },

  btnStyle(state) {
    return state.obj.data.settings?.btnStyle ?? defaultPage().settings.btnStyle
  },

  customDomainName(state) {
    return state.obj.customDomain?.name ?? null
  },

  customDomainStatus(state) {
    return state.obj.customDomain?.status ?? null
  },

  customDomainIsActive(state) {
    return state.obj.customDomain?.status === 'active'
  },

  colors(state) {
    const colors = state.obj.data.settings?.colors

    if (colors && Object.keys(colors).length === 2) {
      return colors
    }

    return defaultPage().settings.colors
  },

  sectionsData(state, getters) {
    // const t0 = performance.now()
    const fractions = {
      '1:1': 3,
      '1:2': 1.5,
      '1:3': 1,
      '2:3': 2,
    }

    return getters.items.map(section => {
      const { bgColor, bgPattern } = section.props
      const bgImage = section.settings?.bgImage ?? {}

      const meta = {}

      meta.bgFlags = {
        img: !!(bgImage.filename || bgImage.url),
        color: typeof bgColor?.color === 'string',
        gradient: typeof bgColor?.gradient?.color === 'string',
        pattern: typeof bgPattern?.name === 'string',
      }

      const rows = section.items

        // Make rows/cols array structure of section items (which has a flat data structure)
        .reduce(
          (prev, item, idx, arr) => {
            const currentRowCount = prev[prev.length - 1]
              .map(({ width }) => width)
              .reduce((rowPrev, rowCurr) => rowPrev + rowCurr, 0)

            const itemColWidth = fractions[item.props.width]
            const rowIsFull = currentRowCount === 3
            const rowWillOverflow = currentRowCount + itemColWidth > 3

            if (rowIsFull || rowWillOverflow) {
              prev.push([])
            }

            const hasBg = !!(
              item.props.bgColor?.color || item.props.bgColor?.gradient
            )

            const hasShadow = !!item.props.boxShadow

            const hasMedia = ['ImageBlock', 'MapBlock'].includes(item.name)

            const hasFilledMedia =
              hasMedia && item.items[0].props.appearance === 'fill'

            prev[prev.length - 1].push({
              uid: item.uid,
              width: itemColWidth,
              hasBg,
              hasShadow,
              hasFilledMedia,
              isExpanded:
                section.props.expand && (hasBg || hasShadow || hasFilledMedia),
            })
            return prev
          },
          [[]]
        )

        // ROWS
        // Now set props for every dynamic row
        .map((row, rowIdx, rows) => {
          const rowHasBgCol = row.some(col => col.hasBg || col.hasShadow)

          const rowHasFilledMedia = row.some(col => col.hasFilledMedia)

          const allColsHaveBg = row.every(col => col.hasBg || col.hasShadow)
          const rowHasExpandedCol =
            row.some(col => col.isExpanded) &&
            (rowHasBgCol || rowHasFilledMedia)

          const isFirstRow = rowIdx === 0
          const isLastRow = rowIdx === rows.length - 1

          meta.isMultiRow = rows.length > 1

          if (isFirstRow) {
            meta.firstRowIsExpanded = rowHasExpandedCol
            meta.firstRowHasBg = rowHasBgCol
          }

          if (isLastRow) {
            meta.lastRowIsExpanded = rowHasExpandedCol
            meta.lastRowHasBg = rowHasBgCol
          }

          // COLS
          return row.map((col, colIdx, cols) => {
            col = Object.assign(col, {
              isFirstRow,
              isLastRow,
              rowHasBgCol,
              allColsHaveBg,
              rowHasFilledMedia,
              rowHasExpandedCol,
              prevRowHasBgCol: rows[rowIdx - 1]?.some(
                col => col.hasBg || col.hasFilledMedia
              ),
              prevRowHasExpandedCol: rows[rowIdx - 1]?.some(
                col => col.isExpanded && (col.hasBg || col.hasFilledMedia)
              ),
            })

            if (colIdx === 0 && cols.length === 1) {
              col.pos = 'only'
            } else if (colIdx === 0) {
              col.pos = 'first'
            } else if (colIdx === 1 && cols.length === 2) {
              col.pos = 'last'
            }

            return col
          })
        })

      // Make flat array for picking props used by mobile
      const flattedRow = rows.flat()

      meta.firstBlockIsExpanded =
        flattedRow[0].isExpanded &&
        (flattedRow[0].hasBg || flattedRow[0].hasFilledMedia)

      const lastItem = flattedRow[flattedRow.length - 1]

      meta.lastBlockIsExpanded =
        lastItem.isExpanded && (lastItem.hasBg || lastItem.hasFilledMedia)

      meta.hasBg =
        Object.values(meta.bgFlags).some(item => item) || meta.lastRowIsExpanded

      meta.hasTilt = !!(meta.hasBg && section.props.sectionDivider?.angle)
      // const t1 = performance.now()
      // console.log(t1 - t0 + ' ms')

      return { meta, rows }
    })
  },
}
