import uid from '@/utils/uid'
import { hrStyle } from '~/config/props'

export const config = {
  title: 'Horizontal Line',

  root: {
    props: {
      hrStyle: hrStyle(),
    },
  },
}

export function HorizontalLine() {
  return {
    uid: uid(),

    type: 'item',

    name: 'HorizontalLine',

    props: { ...config.root.props },

    content: {},
  }
}
