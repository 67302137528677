import { ImageItem } from '../items/ImageItem'

import uid from '@/utils/uid'
import {
  align,
  // bgColor,
  // fontSize,
  // imgCrop,
  width,
} from '@/config/props'

export const config = {
  title: 'Single image',

  root: {
    props: {
      width: width(),
      // fontSize: fontSize(),
      align: align('left'),
      // bgColor: bgColor(),
      // expand: expand(),
    },
    additionalItems: [],
    // additionalItems: [{ label: 'Headline', name: 'Headline', max: 1 }],
  },

  ImageItem: {
    props: {
      width: width(),
      // imgCrop: imgCrop(),
    },

    opts: {
      appearance: {
        org: 'Orginal',
        fill: 'Fill',
        shape: 'Shape',
        blob: 'Blob',
      },
    },

    dimensions: {
      width: 1000,
      height: 562,
    },
  },
}

export function ImageBlock({ settings } = {}) {
  return {
    uid: uid(),

    name: 'ImageBlock',

    type: 'block',

    props: {
      ...config.root.props,
    },

    items: [
      ImageItem({
        settings,
        props: {
          appearance: 'org',
        },
      }),
    ],
  }
}
