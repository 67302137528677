import uid from '@/utils/uid'

export const config = {
  title: 'Text field',

  root: {
    props: {},
    settings: {},
  },
}

export function TextField({
  inputType = 'text',
  placeholder = '',
  alias = 'tagline',
} = {}) {
  return {
    uid: uid(),

    type: 'item',

    name: 'TextField',

    alias,

    props: { ...config.root.props },

    settings: { ...config.root.settings, ...{ inputType, placeholder } },
  }
}
