export default async function getDummyText({
  numItems = 1,
  sents = 3,
  html = false,
  asArray,
  type = 'paragraphs',
} = {}) {
  if (!process.browser) {
    return
  }

  try {
    let url = `https://dummytext-api.vercel.app/api/${type}`

    if (type === 'paragraphs') {
      url += `/${numItems}/${sents}`
    } else {
      url += `?num=${numItems}`
    }

    if (html) {
      url += url.includes('?') ? '&' : '?'
      url += 'p=1'
    }

    const response = await fetch(url)

    let text = await response.text()

    if (numItems > 1 && asArray !== false) {
      text = text.split('\n').filter(item => item)
      return text
    }

    return text
  } catch (error) {
    console.log(error)
  }
}
