import { Btn, config as BtnConf } from './Btn'
import { Map, config as MapConf } from './Map'
import { Icon, config as IconConf } from './Icon'
import { Card, config as CardConf } from './Card'
import { Embed, config as EmbedConf } from './Embed'
import { Quote, config as QuoteConf } from './Quote'
import { FaqItem, config as FaqItemConf } from './FaqItem'
import { Tagline, config as TaglineConf } from './Tagline'
import { ImageItem, config as ImageItemConf } from './ImageItem'
import { Feature, config as FeatureConf } from './Feature'
import { Navigation, config as NavigationConf } from './Navigation'
import { Headline, config as HeadlineConf } from './Headline'
import { BodyText, config as BodyTextConf } from './BodyText'
import { RichList, config as RichListConf } from './RichList'
import { PlainText, config as PlainTextConf } from './PlainText'
import { EmailForm, config as EmailFormConf } from './EmailForm'
import { TextField, config as TextFieldConf } from './TextField'
import { SubmitBtn, config as SubmitBtnConf } from './SubmitBtn'
import { SocialLinks, config as SocialLinksConf } from './SocialLinks'
import { HeaderBodyText, config as HeaderBodyTextConf } from './HeaderBodyText'
import { HorizontalLine, config as HorizontalLineConf } from './HorizontalLine'

export const configs = {
  Btn: BtnConf,
  Map: MapConf,
  Icon: IconConf,
  Card: CardConf,
  Embed: EmbedConf,
  Quote: QuoteConf,
  FaqItem: FaqItemConf,
  Tagline: TaglineConf,
  Feature: FeatureConf,
  Headline: HeadlineConf,
  BodyText: BodyTextConf,
  RichList: RichListConf,
  PlainText: PlainTextConf,
  ImageItem: ImageItemConf,
  EmailForm: EmailFormConf,
  TextField: TextFieldConf,
  SubmitBtn: SubmitBtnConf,
  Navigation: NavigationConf,
  SocialLinks: SocialLinksConf,
  HeaderBodyText: HeaderBodyTextConf,
  HorizontalLine: HorizontalLineConf,
}

export const items = {
  Btn,
  Map,
  Icon,
  Card,
  Embed,
  Quote,
  FaqItem,
  Tagline,
  ImageItem,
  Feature,
  Headline,
  BodyText,
  RichList,
  PlainText,
  EmailForm,
  TextField,
  SubmitBtn,
  SocialLinks,
  Navigation,
  HeaderBodyText,
  HorizontalLine,
}
