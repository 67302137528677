import { Map as MapItem } from '../items/Map'

import uid from '@/utils/uid'
import { appearance, width } from '@/config/props'

export const config = {
  title: 'Map',

  root: {
    props: {
      width: width(),
      // expand: expand(),
    },
  },

  Map: {
    props: {
      appearance: appearance(),
    },

    opts: {
      appearance: {
        org: 'Orginal',
        fill: 'Fill',
      },
    },
  },
}

export function MapBlock() {
  return {
    uid: uid(),

    name: 'MapBlock',

    type: 'block',

    items: [MapItem()],

    props: {
      ...config.root.props,
    },
  }
}
