import Vue from 'vue'
import VueLazyload from 'vue-lazyload'
import { commit } from 'vuex-pathify'

Vue.use(VueLazyload, {
  observer: true,

  adapter: {
    loaded() {
      commit('SET_FULL_PAGE_LOADING', null)
    },

    error() {
      commit('SET_FULL_PAGE_LOADING', null)
    },
  },

  // optional
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1,
  },
})
