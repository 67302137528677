import { query } from 'faunadb'
const {
  If,
  Do,
  Now,
  Ref,
  Get,
  Var,
  Let,
  Map,
  Match,
  Count,
  Index,
  Merge,
  Format,
  Lambda,
  Exists,
  Delete,
  Create,
  Update,
  Select,
  Paginate,
  Collection,
} = query

/**
 * @param {string} id
 * @returns {faunaRef}
 */
function getPageRef(id) {
  return Ref(Collection('Pages'), id)
}

/**
 * @param {faunaDocument} page
 * @returns {object}
 */
function getPageObj(page) {
  return Let(
    {
      page,
    },
    Merge(
      { id: Select(['ref', 'id'], Var('page')) },
      Select('data', Var('page'))
    )
  )
}

/**
 * @param {object}
 * @returns {object}
 */

function createPage({ userId, ...rest }) {
  const data = {
    createdAt: Now(),
    updatedAt: Now(),
    userRef: Ref(Collection('Users'), userId),
    ...rest,
  }

  return getPageObj(
    Create(Collection('Pages'), {
      data,
    })
  )
}

/**
 * @param {string} id
 * @returns {object}
 */
function getPageById(id, userId) {
  return Let(
    {
      pageRef: getPageRef(id),
      page: Get(
        Match(Index('Page_by_id_and_userRef'), [
          Var('pageRef'),
          Ref(Collection('Users'), userId),
        ])
      ),
      pageObj: getPageObj(Var('page')),
    },
    Let(
      {
        subscriptionRef: Match(
          Index('Subscription_by_pageRef'),
          Var('pageRef')
        ),
        numFormSubmissions: Count(
          Match(
            Index('FormSubmissions_by_pageRef'),
            Ref(Collection('Pages'), id)
          )
        ),
        pageData: Merge(Var('pageObj'), {
          numFormSubmissions: Var('numFormSubmissions'),
        }),
      },
      If(
        Exists(Var('subscriptionRef')),
        Let(
          {
            subscription: Get(Var('subscriptionRef')),
          },
          Merge(Var('pageData'), {
            subscription: Merge(
              { id: Select(['ref', 'id'], Var('subscription')) },
              Select('data', Var('subscription'))
            ),
          })
        ),
        Var('pageData')
      )
    )
  )
}

/**
 * @param {string} id
 * @param {object} data
 * @returns {fauna document} page
 */
function updatePage(id, data) {
  data = {
    ...data,
    ...{ updatedAt: Now() },
  }

  return getPageObj(
    Update(getPageRef(id), {
      data,
    })
  )
}

/**
 * @param {string} id
 * @returns {fauna document} page
 */
function deletePage(id) {
  const pageRef = getPageRef(id)

  return Do(
    // Delete page
    Delete(pageRef),
    // Delete subscription (We use Map in case a subscription does not exist)
    Map(
      Paginate(Match(Index('Subscription_by_pageRef'), pageRef)),
      Lambda('ref', Delete(Var('ref')))
    ),
    // Delete Form submissions
    Map(
      Paginate(Match(Index('FormSubmissions_by_pageRef'), pageRef)),
      Lambda('ref', Delete(Var('ref')))
    ),
    // Delete published pages
    Map(
      Paginate(Match(Index('PublishedPages_by_pageRef'), pageRef)),
      Lambda('ref', Delete(Select(1, Var('ref'))))
    )
  )
}

function publishPage({ pageId, name }) {
  const pageRef = getPageRef(pageId)
  const pageDoc = Get(pageRef)

  const data = {
    createdAt: Now(),
    pageRef,
    name,
    title: Select(['data', 'title'], pageDoc),
    slug: Select(['data', 'slug'], pageDoc),
    data: Select(['data', 'data'], pageDoc),
    umami: Select(['data', 'umami'], pageDoc, null),
    metadata: Select(['data', 'metadata'], pageDoc, null),
    customDomain: Select(['data', 'customDomain'], pageDoc, null),
  }

  return Do(
    Create(Collection('PublishedPages'), {
      data,
    }),
    updatePage(pageId, {
      publishedAt: Now(),
    })
  )
}

function unpublishPage(pageId) {
  return updatePage(pageId, {
    publishedAt: null,
  })
}

// function getPublishedPages() {}

function getPublishedPageBySlug(slug) {
  return getPageObj(
    Get(
      Match(
        Index('PublishedPages_by_pageRef'),
        Select('ref', Get(Match(Index('Page_by_slug'), slug)))
      )
    )
  )
}

function getPublishedPageByCustomDomain(domain) {
  return getPageObj(
    Get(
      Match(
        Index('PublishedPages_by_pageRef'),
        Select('ref', Get(Match(Index('Page_by_customDomain'), domain)))
      )
    )
  )
}

function getPublishedVersions(pageId) {
  return Select(
    'data',
    Map(
      Paginate(
        Match(
          Index('PublishedPages_by_pageRef'),
          Ref(Collection('Pages'), pageId)
        )
      ),
      Lambda(
        'item',
        Let(
          {
            pubPage: Get(Select(1, Var('item'))),
          },
          {
            id: Select(['ref', 'id'], Var('pubPage')),
            name: Select(['data', 'name'], Var('pubPage'), null),
            createdAt: Select(['data', 'createdAt'], Var('pubPage')),
          }
        )
      )
    )
  )
}

function getPublishedVersion(id) {
  return getPageObj(Get(Ref(Collection('PublishedPages'), id)))
}

function getFormSubmissionsByPageId(id) {
  const pageRef = getPageRef(id)

  return Map(
    Paginate(Match(Index('FormSubmissions_by_pageRef'), pageRef)),
    Lambda(
      'ref',
      Let(
        {
          data: Select('data', Get(Var('ref'))),
        },
        {
          date: Let(
            { date: Select('createdAt', Var('data')) },
            Format('%tF %tT', [Var('date'), Var('date')])
          ),
          formName: Select('formName', Var('data')),
          email: Select('email', Var('data')),
        }
      )
    )
  )
}

export {
  createPage,
  updatePage,
  deletePage,
  getPageById,
  publishPage,
  unpublishPage,
  getPublishedVersion,
  getPublishedVersions,
  getPublishedPageBySlug,
  getPublishedPageByCustomDomain,
  getFormSubmissionsByPageId,
}
