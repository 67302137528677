<template>
  <Page v-if="slug" class="flex flex-col min-h-screen-100">
    <SectionWrapper
      v-for="(section, index) in pageItems"
      :key="section.uid"
      :section="section"
      :index="index"
      class="group relative"
    />

    <div
      v-if="!pageIsPro"
      class="flex justify-end items-baseline p-6 leading-none text-gray-600"
    >
      <a
        class="hover:underline"
        rel="noopener"
        href="https://www.siddler.com/"
        target="_blank"
      >
        <span class="text-sm">by Siddler</span>
      </a>
    </div>

    <CookiePolicy
      v-if="hasTracking && !cookiePolicyIsAccepted"
      :slug="slug"
      :lang="cookiePolicyLang"
      @hide="cookiePolicyIsAccepted = true"
    />
  </Page>
</template>

<script>
import { get } from 'vuex-pathify'
import layout from '~/mixins/layout'
import headMixin from '@/mixins/head'

export default {
  name: 'Public',

  components: {
    Page: () => import('@/components/Page'),
    SectionWrapper: () => import('@/components/SectionWrapper'),
    CookiePolicy: () => import('@/components/PublicPageCookiePolicy'),
  },

  mixins: [layout, headMixin],

  data() {
    return {
      cookiePolicyIsAccepted: true,
    }
  },

  computed: {
    pageItems: get('page/items'),

    hasTracking() {
      return (
        ![undefined, ''].includes(this.metadata?.gaId) ||
        ![undefined, ''].includes(this.metadata?.fbPixel)
      )
    },

    pageIsPro: get('page/isPro'),
    slug: get('page/obj@slug'),
    metadata: get('page/obj@metadata'),

    cookiePolicyLang() {
      return this.$store.state.page.obj.metadata?.cookiePolicyLang ?? 'en'
    },
  },

  mounted() {
    if (process.browser) {
      this.cookiePolicyIsAccepted = localStorage.getItem('cookiePolicyAccepted')
    }
  },
}
</script>
