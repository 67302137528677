import uid from '@/utils/uid'

export const config = {
  title: 'Text',

  editor: { hide: true, extensions: [] },
  root: {
    props: {},
  },
}

export function PlainText({
  content = '<p>This is plain text</p>',
  props = {},
  alias = 'text',
} = {}) {
  return {
    uid: uid(),

    type: 'item',

    name: 'PlainText',

    alias,

    props,

    content: {
      html: content,
    },
  }
}
