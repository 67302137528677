import { query } from 'faunadb'
const { Var, Select, Merge, Update, Let, Epoch, Now, Ref, Create, Collection } =
  query

function getSubscriptionObj(subscription) {
  return Merge(
    { id: Select(['ref', 'id'], subscription) },
    Select('data', subscription)
  )
}

function update(id, data) {
  return Update(Ref(Collection('Subscriptions'), id), {
    data,
  })
}

function createSubscription({ userId, pageId, stripeId, stripePlanId }) {
  return Let(
    {
      subscription: Create(Collection('Subscriptions'), {
        data: {
          createdAt: Now(),
          userRef: Ref(Collection('Users'), userId),
          pageRef: Ref(Collection('Pages'), pageId),
          stripeId,
          stripePlanId,
          status: 'active',
        },
      }),
    },
    getSubscriptionObj(Var('subscription'))
  )
}

function cancelSubscription(id, cancelAt) {
  return Let(
    {
      subscription: update(id, {
        status: 'canceled',
        canceledAt: Now(),
        endsAt: Epoch(cancelAt, 'second'),
      }),
    },
    getSubscriptionObj(Var('subscription'))
  )
}

function resumeSubscription(id) {
  return Let(
    {
      subscription: update(id, {
        status: 'active',
        canceledAt: null,
        endsAt: null,
      }),
    },
    getSubscriptionObj(Var('subscription'))
  )
}

export { createSubscription, cancelSubscription, resumeSubscription }
