import uid from '@/utils/uid'

export const config = {
  title: 'Embed',

  pro: true,

  isSortable: true,

  root: {
    props: {},

    settings: {
      url: null,
      html: null,
    },

    content: {},
  },
}

export function Embed({ settings, alias = 'embed' } = {}) {
  return {
    uid: uid(),

    type: 'item',

    name: 'Embed',

    alias,

    pro: true,

    props: { ...config.root.props },

    settings: { ...config.root.settings, ...settings },

    content: {},
  }
}
