import { ImageItem } from './ImageItem'
import { Headline } from './Headline'
import { BodyText } from './BodyText'

import uid from '@/utils/uid'
import randomImg from '~/utils/randomImg'
import { appearance, bgColor, boxShadow, direction } from '~/config/props'
// import { appearance } from '@/config/props'

export const config = {
  isSortable: true,

  title: 'Card',

  root: {
    props: {
      bgColor: bgColor(),
      direction: direction(),
      boxShadow: boxShadow(),
    },

    additionalItems: [
      { label: 'Image', name: 'ImageItem', max: 1 },
      {
        label: 'Headline',
        name: 'Headline',
        max: 1,
        opts: {
          weight: 'h3',
        },
      },
      { label: 'Text', name: 'BodyText', max: 1 },
      { label: 'Button', name: 'Btn', max: 1 },
    ],
  },

  ImageItem: {
    props: {
      appearance: appearance('shape@circle'),
    },

    opts: {
      appearance: {
        fill: 'Fill',
        shape: 'Shape',
        blob: 'Blob',
      },
    },

    dimensions: {
      width: 700,
      height: 400,
    },
  },
}

export async function Card({
  headlineContent,
  bodyContent,
  alias = 'card',
} = {}) {
  return {
    uid: uid(),

    type: 'item',

    name: 'Card',

    alias,

    props: { ...config.root.props },

    items: [
      ImageItem({
        settings: {
          url: randomImg(),
          source: 'unsplash',
        },

        props: {
          appearance: 'fill',
          effect: null,
        },
      }),

      await Headline({
        weight: 'h3',
        content: headlineContent,
      }),

      await BodyText({ content: bodyContent }),
    ],
  }
}
