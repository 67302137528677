import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _774e842f = () => interopDefault(import('../pages/lab/blob.vue' /* webpackChunkName: "pages/lab/blob" */))
const _55a7c92b = () => interopDefault(import('../pages/lab/doodle.vue' /* webpackChunkName: "pages/lab/doodle" */))
const _7a024cea = () => interopDefault(import('../pages/lab/wave.vue' /* webpackChunkName: "pages/lab/wave" */))
const _38e74285 = () => interopDefault(import('../pages/edit/_id.vue' /* webpackChunkName: "pages/edit/_id" */))
const _c1fde4ec = () => interopDefault(import('../pages/reset/_token.vue' /* webpackChunkName: "pages/reset/_token" */))
const _fd9c1efe = () => interopDefault(import('../pages/verify-email/_token.vue' /* webpackChunkName: "pages/verify-email/_token" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/lab/blob",
    component: _774e842f,
    name: "lab-blob"
  }, {
    path: "/lab/doodle",
    component: _55a7c92b,
    name: "lab-doodle"
  }, {
    path: "/lab/wave",
    component: _7a024cea,
    name: "lab-wave"
  }, {
    path: "/edit/:id?",
    component: _38e74285,
    name: "edit-id"
  }, {
    path: "/reset/:token?",
    component: _c1fde4ec,
    name: "reset-token"
  }, {
    path: "/verify-email/:token?",
    component: _fd9c1efe,
    name: "verify-email-token"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
