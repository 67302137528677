export default {
  inter: {
    title: 'Inter',
    loader: {
      google: {
        families: ['Inter:ital,wght@0,400;0,600;0,800;1,400;1,800'],
      },
    },
  },

  'alegreya-alegreyaSans': {
    title: 'Alegreya / Alegreya Sans',
    desc: 'Paired with Alegreya Sans',
    loader: {
      google: {
        families: [
          'Alegreya+Sans:400,400i,700,700i',
          'Alegreya:400,400i,700,700i',
        ],
      },
    },
  },

  'alegreyaSans-alegreya': {
    title: 'Alegreya Sans / Alegreya',
    desc: 'Paired with Alegreya',
    loader: {
      google: {
        families: [
          'Alegreya+Sans:400,400i,700,700i',
          'Alegreya:400,400i,700,700i',
        ],
      },
    },
  },

  grandstander: {
    title: 'Grandstander',
    loader: {
      google: {
        families: ['Grandstander:ital,wght@0,400;0,700;1,400;1,700'],
      },
    },
  },

  jost: {
    title: 'Jost',
    loader: {
      google: {
        families: ['Jost:ital,wght@0,400;0,700;1,400;1,700'],
      },
    },
  },

  'playfairDisplay-sourceSansPro': {
    title: 'Playfair Display / Source Sans Pro',
    desc: 'Paired with Source Sans Pro',
    loader: {
      google: {
        families: [
          'Playfair+Display:ital,wght@0,400;0,800;1,400;1,800',
          'Source+Sans+Pro:400,400i,700,700i',
        ],
      },
    },
  },

  'poppins-sourceSansPro': {
    title: 'Poppins / Source Sans Pro',
    desc: 'Paired with Source Sans Pro',
    loader: {
      google: {
        families: [
          'Poppins:400,400i,700,700i',
          'Source+Sans+Pro:400,400i,700,700i',
        ],
      },
    },
  },

  piazzolla: {
    title: 'Piazzolla',
    loader: {
      google: {
        families: ['Piazzolla:ital,wght@0,400;0,700;1,400;1,700'],
      },
    },
  },

  recursive: {
    title: 'Recursive',
    loader: {
      google: {
        api: 'https://fonts.googleapis.com/css2',
        families: ['Recursive:wght,CASL,MONO@400..800,0..1,0..1'],
      },
    },
  },

  robotoMono: {
    title: 'Roboto Mono',
    loader: {
      google: {
        api: 'https://fonts.googleapis.com/css2',
        families: ['Roboto+Mono:ital,wght@0,400;0,700;1,400;1,700'],
      },
    },
  },

  spaceMono: {
    title: 'Space Mono',
    loader: {
      google: {
        families: ['Space+Mono:400,400i,700,700i'],
      },
    },
  },

  spaceGrotesk: {
    title: 'Space Grotesk',
    loader: {
      google: {
        families: ['Space+Grotesk:wght@400;700'],
      },
    },
  },

  titilliumWeb: {
    title: 'Titillium Web',
    loader: {
      google: {
        families: ['Titillium+Web:400,400i,700,700i'],
      },
    },
  },
}
