<template>
  <div class="flex overflow-hidden flex-col max-h-screen-100 min-h-screen-100">
    <transition name="fade">
      <AdminBar
        v-if="!loadAsPreview && !$fetchState.pending"
        class="shrink-0"
      />
    </transition>
    <transition name="fade">
      <Nuxt v-if="!$fetchState.pending" class="flex-grow" />
    </transition>
  </div>
</template>

<script>
import { get, dispatch } from 'vuex-pathify'
import layout from '~/mixins/layout'

export default {
  name: 'Admin',

  components: {
    AdminBar: () => import('@/components/AdminBar'),
  },

  mixins: [layout],

  data() {
    return {
      loaded: false,
    }
  },

  async fetch() {
    if (this.authToken) {
      try {
        await dispatch('user/get')
      } catch (e) {}
    }

    this.loaded = true
  },

  fetchOnServer: false,

  computed: {
    authToken: get('auth/token'),
    loadAsPreview: get('loadAsPreview'),
  },
}
</script>

<style lang="postcss">
@import '~/assets/css/admin';
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
