import defaultPage from '@/config/defaultPage'

export default {
  // Anonymous users
  getDefault({ commit }) {
    commit('SET_OBJ_DATA', { value: defaultPage() })
  },

  // Logged in users in admin-mode
  async getById({ commit, dispatch, rootState }, pageId) {
    const data = await this.$db.getPageById(pageId, rootState.user.data.id)
    commit('SET_OBJ', data)

    dispatch('history/init', null, { root: true })
  },

  async getPublicPage({ state, rootState, commit, dispatch }, host) {
    const isSubdomain = host.includes(this.$config.appDomain)
    if (isSubdomain) {
      return await dispatch('getBySubdomain', host.split('.')[0])
    } else {
      return await dispatch('getByCustomDomain', host)
    }
  },

  // This is used when viewing the public version of a page with no custom domain
  async getBySubdomain({ commit, dispatch }, slug) {
    const data = await this.$db.getPublishedPageBySlug(slug)
    commit('SET_OBJ', data)
    return data
  },

  // This is used when viewing the public version of a page that has a custom domain
  async getByCustomDomain({ state, commit, dispatch }, customDomain) {
    const data = await this.$db.getPublishedPageByCustomDomain(customDomain)
    commit('SET_OBJ', data)
    return data
  },

  async update(
    { state, rootState, commit, dispatch },
    { mutation, saveToServer = true, ...props } = {}
  ) {
    dispatch('history/init', null, { root: true })

    if (mutation) {
      commit(mutation, props)
    }

    if (saveToServer) {
      dispatch('history/save', null, { root: true })
      await dispatch('save', { data: state.obj.data })
    }
  },

  async create({ commit, dispatch, rootState }, { title, slug, pageData }) {
    const blacklistSlugs = ['embed']

    if (blacklistSlugs.includes(slug)) {
      throw new Error('instance not unique')
    }

    const data = await this.$db.createPage({
      userId: rootState.user.data.id,
      title,
      slug,
      data: pageData,
    })

    commit('SET_OBJ', data)

    return data.id
  },

  async save({ commit, state, rootState }, payload) {
    if (!rootState.user.data.id || !state.obj.id) {
      return false
    }

    commit('SET_SAVING', true)

    const data = await this.$db.updatePage(state.obj.id, payload)

    commit('SET_UPDATED_AT', data.updatedAt)
    commit('SET_SAVING', false)
    return data
  },

  async publish(
    { state, dispatch, commit, getters },
    { versionName = undefined } = {}
  ) {
    const payload = {
      pageId: state.obj.id,
      name: versionName,
    }

    const data = await this.$db.publishPage(payload)

    // Bust cache - A new cache will be generated on first visit of a public page that is uncached
    const cacheKey = getters.customDomainIsActive
      ? getters.customDomainName
      : `${state.obj.slug}.${this.$config.appDomain}`

    try {
      await fetch(`/api/uncache`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cacheKey,
        }),
      })
    } catch (e) {
      console.log(e)
    }

    commit('SET_PUBLISHED_AT', data.publishedAt)
  },

  async unpublish(
    { rootState, state, dispatch, commit },
    pageId = state.obj.id
  ) {
    await this.$db.unpublishPage(pageId)
  },

  async delete({ commit, dispatch }, page) {
    // Cancel subscription if page has an active subscription
    if (page.subscription?.status === 'active') {
      await dispatch('cancelSubscription', page.subscription)
    }

    await this.$db.deletePage(page.id)

    commit('RESET')
    commit('user/REMOVE_PAGE', page.id, { root: true })
  },

  async createSubscription({ commit, state }, payload) {
    const data = await this.$db.createSubscription(payload)
    commit('SET_OBJ_SUBSCRIPTION', data)
  },

  async cancelSubscription(
    { commit, state },
    subscription = state.obj.subscription
  ) {
    const { cancel_at: cancelAt } = await this.$api(
      `/stripe/subscription/cancel`,
      {
        subscriptionId: subscription.stripeId,
      }
    )

    const data = await this.$db.cancelSubscription(subscription.id, cancelAt)

    commit('SET_OBJ_SUBSCRIPTION', data)
  },

  async resumeSubscription({ commit, state }) {
    await this.$api(`/stripe/subscription/resume`, {
      subscriptionId: state.obj.subscription.stripeId,
    })

    const data = await this.$db.resumeSubscription(state.obj.subscription.id)
    commit('SET_OBJ_SUBSCRIPTION', data)
  },
}
