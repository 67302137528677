import { HeaderBodyText } from '../items/HeaderBodyText'
import { Headline } from '../items/Headline'

import {
  fontSize,
  align,
  appearance,
  bgColor,
  effect,
  width,
  boxShadow,
} from '@/config/props'

import uid from '@/utils/uid'

import getDummyText from '@/utils/getDummyText'

export const config = {
  title: 'Rich content',

  root: {
    props: {
      width: width(),
      fontSize: fontSize(),
      align: align('left'),
      bgColor: bgColor(),
      boxShadow: boxShadow(),
      // expand: expand(),
    },

    additionalItems: [
      { label: 'Headline', name: 'Headline' },
      { label: 'Text', name: 'HeaderBodyText' },
      { label: 'Button', name: 'Btn' },
      { label: 'Image', name: 'ImageItem' },
      {
        label: 'Social links',
        name: 'SocialLinks',
      },
      // { label: 'Feature', name: 'Feature' },
      // { label: 'Card', name: 'Card' },
      // { label: 'Quote', name: 'Quote' },
      { label: 'Email form', name: 'EmailForm' },
      { label: 'Embed', name: 'Embed' },
      { label: 'Horizontal line', name: 'HorizontalLine' },
      { label: 'Rich list', name: 'RichList' },
    ],
  },

  Headline: {
    // props: {
    //   effect: effect(),
    // },
    // opts: {
    //   effect: {
    //     none: 'None',
    //     gradient: 'Gradient',
    //   },
    // },
  },

  ImageItem: {
    props: {
      width: width(),
      appearance: appearance(),
      effect: effect(),
    },

    opts: {
      appearance: {
        org: 'Orginal',
        shape: 'Shape',
        blob: 'Blob',
      },
    },

    dimensions: {
      width: 1000,
      height: 750,
    },
  },
}

export async function Content() {
  const content = await getDummyText({
    numItems: 2,
    sents: 2,
    asArray: false,
    html: true,
  })

  return {
    uid: uid(),

    name: 'Content',

    type: 'block',

    props: {
      ...config.root.props,
    },

    items: [
      await Headline({ alias: 'headline' }),
      await HeaderBodyText({
        content,
        alias: 'body',
      }),
    ],
  }
}
