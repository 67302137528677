import { Icon } from './Icon'
import { Headline } from './Headline'
import { BodyText } from './BodyText'

import uid from '@/utils/uid'
import { bgColor, bgColorBool, boxShadow } from '@/config/props'

export const config = {
  isSortable: true,

  title: 'Feature',

  root: {
    props: {
      bgColor: bgColor(),
      boxShadow: boxShadow(),
    },

    additionalItems: [
      { label: 'Icon', name: 'Icon', max: 1 },
      { label: 'Text', name: 'BodyText', max: 1 },
    ],
  },

  Icon: {
    props: {
      bgColorBool: bgColorBool(),
    },
  },
}

export async function Feature({
  headlineContent,
  bodyContent,
  alias = 'feature',
} = {}) {
  return {
    uid: uid(),

    type: 'item',

    name: 'Feature',

    alias,

    props: { ...config.root.props },

    items: [
      Icon(),
      await Headline({ weight: 'h3', content: headlineContent }),
      await BodyText({ content: bodyContent }),
    ],
  }
}
