import uid from '@/utils/uid'
import getDummyText from '@/utils/getDummyText'

export const config = {
  title: 'Tagline',

  editor: { hide: true, extensions: [] },
  root: {
    props: {},
  },
}

export async function Tagline({
  content = '',
  props = {},
  alias = 'tagline',
} = {}) {
  if (!content) {
    content = `<p>${await getDummyText({
      numItems: 1,
      type: 'headline',
    })}</p>`
  }

  return {
    uid: uid(),

    type: 'item',

    name: 'Tagline',

    alias,

    props,

    content: {
      html: content,
    },
  }
}
