export default ({ app, $config, store, redirect, route, $toast }, inject) => {
  async function query(path, payload = {}, includeCredentials = true) {
    const response = await fetch(`${this.$config.apiRootUrl}${path}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: includeCredentials ? 'include' : 'omit',
      method: 'POST',
      body: JSON.stringify(payload),
    })

    if (response.status === 401 && route.path !== '/') {
      store.commit('page/RESET')
      await store.dispatch('auth/logout')
      this.$toast.error('You have been logged out')
      redirect('/')
    }

    if (!response.ok) {
      throw new Error(response.status)
    }

    return response.json()
  }

  inject('api', query)
}
