export default function () {
  return {
    props: { bgColor: { color: 'light' } },
    settings: {
      theme: 'default',
      colors: {
        main: 'indigo',
        accent: 'orange',
      },
      roundedCorners: true,
      btnShape: 'rounded',
      btnStyle: 'gradient',
    },
    items: [],
  }
}
