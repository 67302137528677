<template>
  <Spinner
    class="fill-current"
    :class="[
      mode,
      { 'w-12 h-12': size === 'default', 'w-5 h-5': size === 'small' },
    ]"
  />
</template>

<script>
export default {
  name: 'LoadingIndicator',
  components: {
    Spinner: () => import('~/assets/loading-bars.svg?inline'),
  },

  props: {
    mode: {
      type: String,
      default: 'dark',
    },

    size: {
      type: String,
      default: 'default',
    },
  },

  computed: {},
}
</script>

<style lang="postcss" scoped></style>
