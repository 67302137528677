import { query } from 'faunadb'
const { Ref, Now, Create, Collection, TimeAdd } = query

function createToken({ userId, type, token }) {
  return Create(Collection('Tokens'), {
    data: {
      userRef: Ref(Collection('Users'), userId),
      type,
      token,
      expiresAt: TimeAdd(Now(), 1, 'hour'),
    },
  })
}

export { createToken }
