import uid from '@/utils/uid'
import icons from '@/config/icons'
import randomNumber from '~/utils/randomNumber'

export const config = {
  title: 'Icon',

  root: {
    props: {},

    settings: {
      name: null,
    },
  },
}

export function Icon({
  settings = { name: icons[randomNumber(0, icons.length - 1)] },
  alias = 'icon',
} = {}) {
  return {
    uid: uid(),

    type: 'item',

    name: 'Icon',

    alias,

    props: { ...config.root.props },

    settings,

    content: {},
  }
}
