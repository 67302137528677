<template>
  <span
    class="
      inline-flex
      px-2
      font-bold
      leading-snug
      text-blue-600
      uppercase
      bg-blue-100
      rounded
    "
    >PRO</span
  >
</template>

<script>
export default {}
</script>

<style></style>
