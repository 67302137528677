import { Quote } from '../items/Quote'
import uid from '@/utils/uid'
import getDummyText from '@/utils/getDummyText'
import { align, bgColor, boxShadow, fontSize, width } from '~/config/props'

export const config = {
  title: 'Quotes',

  root: {
    props: {
      width: width(),
      fontSize: fontSize(),
      align: align('center'),
      bgColor: bgColor(),
      boxShadow: boxShadow(),
      // expand: expand(),
    },

    additionalItems: [
      { label: 'Headline', name: 'Headline', max: 1 },
      { label: 'Intro', name: 'BodyText', max: 1 },
      { label: 'Quote item', name: 'Quote' },
    ],
  },
}

export async function Quotes() {
  const bodyContent = await getDummyText({
    numItems: 1,
    sents: 3,
    html: true,
  })

  const items = [await Quote({ bodyContent })]

  return {
    uid: uid(),

    name: 'Quotes',

    type: 'block',

    items,

    props: {
      ...config.root.props,
    },
  }
}
