import uid from '@/utils/uid'
import { appearance, boxShadow, effect } from '~/config/props'

export const config = {
  title: 'Image',

  root: {
    props: {
      appearance: appearance(),
      effect: effect(),
      boxShadow: boxShadow(),
    },

    opts: {
      effect: { none: 'None', bw: 'Grayscale', blend: 'Blend' },
    },

    settings: {
      url: null,
      filename: null,
      width: null,
      height: null,
      altText: null,
      searchQuery: null,
    },
  },
}

export function ImageItem({ props = {}, settings = {}, alias = 'image' } = {}) {
  return {
    uid: uid(),

    type: 'item',

    name: 'ImageItem',

    alias,

    props: { ...config.root.props, ...props },

    settings: { ...config.root.settings, ...settings },

    content: {},
  }
}
