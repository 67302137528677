import createPersistedState from 'vuex-persistedstate'

export default ({ store, route }) => {
  if (store.state.user.data.id || store.state.isPublicPage) {
    return false
  }

  window.onNuxtReady(() => {
    createPersistedState({
      paths: ['page'],
      getState(key, storage) {
        return JSON.parse(storage.getItem(key))
      },

      // Only trigger if guest user on index page
      filter(obj) {
        if (store.state.user.data.id || store.state.isPublicPage) {
          return false
        }
        return route.name === 'index' && obj.type.split('/')[0] === 'page'
      },
    })(store)
  })
}
