import Vue from 'vue'

const flabel = {
  inserted(el) {
    el.classList.add('flabel', 'flabel--inactive')
    el.querySelector('span').classList.add('flabel__label')

    const $input = el.querySelector(
      'input[type="text"], input[type="email"], input[type="url"], input[type="password"], select'
    )
    const $textarea = el.querySelector('textarea')
    const $checkbox = el.querySelector('input[type="checkbox"]')

    if ($input) {
      el.classList.add('flabel--input')
      $input.classList.add('flabel__input')
    } else if ($textarea) {
      el.classList.add('flabel--textarea')
      $textarea.classList.add('flabel__input')
    } else if ($checkbox) {
      el.classList.add('flabel--checkbox')
      $checkbox.classList.add('flabel__input')
    }
  },

  componentUpdated(el) {
    const $input = el.querySelector('input, textarea, select')
    if (![null, '', undefined].includes($input.value)) {
      el.classList.add('flabel--has-input')
    }
  },

  bind(el) {
    const $input = el.querySelector('input, textarea, select')

    setTimeout(() => {
      if (![null, '', undefined].includes($input.value)) {
        el.classList.add('flabel--has-input')
      }
    }, 0)

    $input.addEventListener('focus', () => {
      el.classList.remove('flabel--inactive')
      el.classList.add('flabel--active')
    })

    $input.addEventListener('blur', () => {
      if (![null, '', undefined].includes($input.value)) {
        el.classList.add('flabel--has-input')
      } else {
        el.classList.add('flabel--inactive')
        el.classList.remove('flabel--has-input')
      }
      el.classList.remove('flabel--active')
    })
  },
}

Vue.directive('flabel', flabel)
