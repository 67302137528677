export default {
  async get({ commit }) {
    const { data } = await this.$db.getUser()
    commit('SET_DATA', data)
  },

  async update({ commit }, payload) {
    const data = await this.$db.updateUser(payload)
    commit('UPDATE_DATA', data)
  },

  async updatePassword({ commit }, data) {
    await this.$db.updateUserPassword(data)
  },
}
