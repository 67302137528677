export default [
  'activity',
  'adjustments-horizontal',
  'aerial-lift',
  'alarm',
  'alert-triangle',
  'alien',
  'ambulance',
  'anchor',
  'aperture',
  'apps',
  'archive',
  'at',
  'atom',
  'award',
  'ball-american-football',
  'ball-basketball',
  'ball-football',
  'ball-tennis',
  'ball-volleyball',
  'bandage',
  'bed',
  'bike',
  'bolt',
  'bone',
  'book',
  'box',
  'brand-android',
  'brand-apple-arcade',
  'brand-apple',
  'briefcase',
  'browser',
  'brush',
  'bucket',
  'building-carousel',
  'building-castle',
  'building-community',
  'building-factory',
  'bulb',
  'bus',
  'businessplan',
  'calculator',
  'calendar-event',
  'camera',
  'car',
  'cash',
  'certificate',
  'checkbox',
  'christmas-tree',
  'circle-square',
  'circle',
  'circles',
  'cloud-rain',
  'cloud-snow',
  'cloud-storm',
  'code',
  'coin',
  'comet',
  'compass',
  'confetti',
  'crown',
  'cut',
  'dashboard',
  'database',
  'device-desktop',
  'device-laptop',
  'device-mobile',
  'device-speaker',
  'device-tablet',
  'device-tv',
  'device-watch',
  'devices',
  'diamond',
  'dice',
  'directions',
  'disabled',
  'disc',
  'discount',
  'drone',
  'droplet',
  'ear',
  'egg',
  'eye',
  'fall',
  'feather',
  'fingerprint',
  'firetruck',
  'flag',
  'flame',
  'flare',
  'flask',
  'forklift',
  'friends',
  'gas-station',
  'gauge',
  'ghost',
  'gift',
  'glass-full',
  'globe',
  'golf',
  'gps',
  'growth',
  'hand-little-finger',
  'hand-middle-finger',
  'hand-rock',
  'hand-stop',
  'hanger',
  'headphones',
  'heart',
  'helicopter',
  'home',
  'ice-cream',
  'infinity',
  'info-circle',
  'key',
  'layout-2',
  'layout-grid',
  'leaf',
  'lifebuoy',
  'link',
  'list-check',
  'lock-open',
  'lock',
  'macro',
  'mail',
  'mailbox',
  'map',
  'mars',
  'math-symbols',
  'medal',
  'medical-cross',
  'microphone',
  'mood-boy',
  'mood-confuzed',
  'mood-crazy-happy',
  'mood-cry',
  'mood-happy',
  'mood-kid',
  'mood-neutral',
  'mood-sad',
  'mood-smile',
  'mood-suprised',
  'mood-tongue',
  'moon',
  'moped',
  'motorbike',
  'mug',
  'music',
  'number-0',
  'number-1',
  'number-2',
  'number-3',
  'number-4',
  'number-5',
  'number-6',
  'number-7',
  'number-8',
  'number-9',
  'package',
  'pacman',
  'paint',
  'palette',
  'paperclip',
  'parachute',
  'pencil',
  'perspective',
  'phone',
  'photo',
  'pill',
  'pin',
  'pizza',
  'plane',
  'planet',
  'plant-2',
  'plant',
  'play-card',
  'plug',
  'power',
  'propeller',
  'puzzle',
  'rainbow',
  'receipt-2',
  'receipt-tax',
  'receipt',
  'recycle',
  'refresh',
  'repeat',
  'ripple',
  'road-sign',
  'rocket',
  'rotate-clockwise',
  'route',
  'ruler-2',
  'ruler',
  'run',
  'sailboat',
  'satellite',
  'scale',
  'school',
  'scissors',
  'scooter',
  'search',
  'seeding',
  'send',
  'settings',
  'shield-check',
  'shield',
  'ship',
  'shirt',
  'shopping-cart',
  'signature',
  'skateboard',
  'slice',
  'snowflake',
  'soccer-field',
  'speakerphone',
  'speedboat',
  'stack',
  'stairs',
  'star',
  'stars',
  'stethoscope',
  'submarine',
  'sun',
  'swimming',
  'switch-3',
  'tag',
  'tallymarks',
  'target',
  'temperature',
  'template',
  'tent',
  'thumb-down',
  'thumb-up',
  'ticket',
  'tir',
  'tool',
  'tools-kitchen-2',
  'tools',
  'tournament',
  'track',
  'trash',
  'tree',
  'trees',
  'trending-up',
  'triangle-square-circle',
  'trident',
  'trophy',
  'truck-delivery',
  'umbrella',
  'urgent',
  'user',
  'users',
  'vaccine',
  'venus',
  'video',
  'virus',
  'volume',
  'walk',
  'wallet',
  'wand',
  'wifi',
  'wind',
  'windmill',
  'window',
  'woman',
  'world',
  'yin-yang',
]
