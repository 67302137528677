import { Embed as EmbedItem } from '../items/Embed'

import uid from '@/utils/uid'

import { align, bgColor, boxShadow, fontSize, width } from '@/config/props'

export const config = {
  title: 'Embed',

  pro: true,

  root: {
    props: {
      width: width(),
      fontSize: fontSize(),
      align: align('center'),
      bgColor: bgColor(),
      boxShadow: boxShadow(),
      // expand: expand(),
    },

    additionalItems: [
      { label: 'Headline', name: 'Headline', max: 1 },
      { label: 'Intro', name: 'BodyText', max: 1 },
      { label: 'Embed item', name: 'Embed' },
    ],
  },
}

export function Embed({ settings } = {}) {
  return {
    uid: uid(),

    name: 'Embed',

    type: 'block',

    items: [EmbedItem({ settings })],

    props: {
      ...config.root.props,
    },
  }
}
