export default ({ store }) => {
  if (process.env.NODE_ENV === 'development' || store.state.isAdminMode) {
    return
  }

  const publicGaId = store.state.page.obj.metadata?.gaId

  if (!publicGaId) {
    return
  }

  /*eslint-disable */
  ;(function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r
    ;(i[r] =
      i[r] ||
      function () {
        ;(i[r].q = i[r].q || []).push(arguments)
      }),
      (i[r].l = 1 * new Date())
    ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
    a.async = 1
    a.src = g
    m.parentNode.insertBefore(a, m)
  })(
    window,
    document,
    'script',
    'https://www.google-analytics.com/analytics.js',
    'ga'
  )
  /* eslint-enable */

  window.ga('create', publicGaId, 'auto', 'siteTracker') // eslint-disable-line

  window.ga('siteTracker.send', {
    // eslint-disable-line
    hitType: 'pageview',
    title: document.title,
    location: location.href,
    page: location.pathname,
  })
}
