import { HorizontalLine as HorizontalLineItem } from '../items/HorizontalLine'

import uid from '@/utils/uid'

export const config = {
  title: 'Horizontal line',

  canBeCombined: false,
  canHaveBgImage: false,
  canHaveBgColor: false,
  canHaveBgPattern: false,
  canChangeHeight: false,
  canBeWide: false,

  root: {
    props: {},
  },
}

export function HorizontalLine() {
  return {
    uid: uid(),

    name: 'HorizontalLine',

    type: 'block',

    items: [HorizontalLineItem()],

    props: {
      ...config.root.props,
    },
  }
}
