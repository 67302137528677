import uid from '@/utils/uid'

export const config = {
  title: 'Button',

  editor: { hide: true, extensions: [] },

  root: {
    props: {},

    additionalItems: [],

    settings: {
      url: null,
      sectionUid: null,
    },
  },
}

export function Btn({
  label = 'This is a button',
  settings,
  alias = 'btn',
} = {}) {
  return {
    uid: uid(),

    type: 'item',

    name: 'Btn',

    alias,

    props: { ...config.root.props },

    settings: settings || { ...config.root.settings },

    content: {
      html: label,
    },
  }
}
