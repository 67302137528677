<template>
  <a
    href="#"
    v-bind="beaconArticleLink"
    :style="{ fontSize: '120%' }"
    v-text="text"
  />
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '🦉',
    },

    id: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      default: 'inline',
    },
  },

  computed: {
    beaconArticleLink() {
      return { [`data-beacon-article-${this.type}`]: this.id }
    },
  },
}
</script>
