<template>
  <div class="flex flex-col justify-center items-center h-screen">
    <a href="https://www.siddler.com">
      <img
        src="~/assets/siddler-logo2.svg"
        class="mx-auto"
        alt="Siddler logo"
        width="150"
        height="38"
      />
    </a>

    <h1 class="mt-8 mb-2 text-2xl text-gray-700" v-text="message" />

    <p v-if="info" class="opacity-75" v-text="info" />

    <p class="mt-8">
      <a class="text-gray-600" :href="$config.appUrl">
        ← <span class="underline hover:no-underline">Back to start</span>
      </a>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  computed: {
    message() {
      const codeMsgs = {
        404: 'Page not found 😕',
        401: 'Not authorized',
      }

      return (
        this.error.message ??
        (codeMsgs[this.statusCode] || 'Something weird happened')
      )
    },

    info() {
      return this.error.info
    },

    statusCode() {
      return this.error.statusCode
    },
  },
}
</script>
