import { Card } from '../items/Card'

import uid from '@/utils/uid'

import { align, bgColor, boxShadow, fontSize, width } from '@/config/props'

import getDummyText from '@/utils/getDummyText'

export const config = {
  title: 'Cards',

  root: {
    props: {
      width: width(),
      fontSize: fontSize(),
      align: align('left'),
      bgColor: bgColor(),
      boxShadow: boxShadow(),
      // expand: expand(),
    },

    additionalItems: [
      { label: 'Headline', name: 'Headline', max: 1 },
      { label: 'Intro', name: 'BodyText', max: 1 },
      { label: 'Card', name: 'Card' },
    ],
  },
}

export async function Cards() {
  const body = (await getDummyText({ numItems: 3, sents: 3 })) || []

  const headlines =
    (await getDummyText({
      type: 'headline',
      numItems: 3,
    })) || []

  const items = await Promise.all(
    new Array(3).fill('').map(async (item, idx) => {
      return await Card({
        bodyContent: body[idx],
        headlineContent: headlines[idx],
      })
    })
  )

  return {
    uid: uid(),

    name: 'Cards',

    type: 'block',

    items,

    props: {
      ...config.root.props,
    },
  }
}
