import { bgColor, bgPattern } from '@/config/props'

import uid from '@/utils/uid'

export const config = {
  title: 'Block',

  root: {
    props: {
      bgColor: bgColor(),
      bgPattern: bgPattern(),
      valign: 'top',
      sectionDivider: { angle: 0 },
    },

    settings: {
      bgImage: {
        url: null,
        filename: null,
        width: null,
        height: null,
        searchQuery: null,
      },
    },
  },
}

export function Section({ items = [] }) {
  return {
    uid: uid(),

    type: 'section',

    props: {
      ...config.root.props,
    },

    settings: {
      bgImage: { ...config.root.settings.bgImage },
    },

    items,
  }
}
